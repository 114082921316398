import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c('custom-snackbar',{ref:"snackbar"}),_c(VDataTable,{staticClass:"w-auto",attrs:{"item-key":"seqipo","hide-default-footer":"","dense":"","headers":_vm.headersItens,"items":_vm.item.produtos,"items-per-page":10,"mobile-breakpoint":0},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c(VProgressLinear,{attrs:{"absolute":"","indeterminate":"","color":"green"}})]},proxy:true},{key:"item.quantidade",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$stringFormat.formatNumber(item.quantidade)))])]}}],null,true)}),_c('hr'),_c(VRow,{staticClass:"mb-4"},[_c(VCol,{staticClass:"font-weight-bold pb-0",attrs:{"cols":"12"}},[_vm._v(_vm._s(_vm.item.utilizacao_externa ? 'Justificativa' : 'Observação')+":")]),_c(VCol,{attrs:{"cols":"12"}},[_vm._v(_vm._s(_vm.item.observacao || '-'))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }