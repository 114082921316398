<template>
<div>
  <custom-snackbar ref="snackbar" />
  <ActionsComponent
      v-model="headers"
      :filtros="filtros"
      :headers-options="headersOptions"
      relatorio-excel="LancamentoCombustivel"
      excel-pdf
      local-storage-save-name="relatorio-lancamento-combustivel"
      @novo-registro="reloadData = true"
  />
  <FilterComponent v-model="filtros"/>
  <ListComponent :filtros="filtros" :headers="headers" :reload-data.sync="reloadData"/>
</div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'

import ActionsComponent from './components/ActionsComponent'
import FilterComponent from './components/FilterComponent'
import ListComponent from './components/ListComponent'

export default {
  components: { ActionsComponent, FilterComponent, ListComponent },
  data: (instance) => ({
    reloadData: false,
    filtros: {
      dataInicio: instance.filtroValue(instance.$route.query.dataInicio, instance.$date.getDateNowIso(), 'date'),
      dataFim: instance.filtroValue(instance.$route.query.dataFim, instance.$date.getDateNowIso(), 'date'),
      empresaId: instance.filtroValue(instance.$route.query.empresaId, '', 'int'),
      producaoId: instance.filtroValue(instance.$route.query.producaoId, '', 'int'),
      bemPatrimonialId: instance.filtroValue(instance.$route.query.bemPatrimonial, '', 'int'),
    },
    headersOptions: [
      { text: '', value: 'data-table-expand', align: 'center', class: 'custom-table--header', obrigatorio: true },
      { text: '#', value: 'id', class: 'custom-table--header', obrigatorio: true },
      { text: 'Empresa', value: 'empresa', class: 'custom-table--header', obrigatorio: true },
      { text: 'Produção', value: 'producao', class: 'custom-table--header', obrigatorio: true },
      { text: 'Data', value: 'data_realizacao', class: 'custom-table--header', obrigatorio: true },
      { text: 'Utilização Externa', value: 'utilizacao_externa', class: 'custom-table--header', obrigatorio: true },
      { text: 'Bem Patrimonial', value: 'bem_patrimonial', class: 'custom-table--header', obrigatorio: true },
      { text: 'Ações', value: 'actions', align: 'center', class: 'custom-table--header', obrigatorio: true },
    ],
    headers: [],
  }),
  mounted () {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Agricola', route: 'agricola' },
      { title: 'Lançamento de Consumo de Combustiveis' },
    ])
  },
  methods: {
    filtroValue (value, valorPadrao, type) {
      if (value === undefined || value === null || value === '') {
        return valorPadrao
      }

      if (type === 'int') {
        return parseInt(value)
      }

      if (type === 'float') {
        return parseFloat(value)
      }

      if (type === 'array') {
        return [].concat(value)
      }

      return value
    },
  },
}
</script>

<style scoped>

</style>
